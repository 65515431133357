import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {updateObject} from 'utils/reducerUtils';
import AudioPlayer from 'components/AudioPlayer';
import Countdown from 'components/Countdown';
import strings from 'utils/strings';
import {isTablet} from 'react-device-detect';
import wordsNo from './data/no.json';
import wordsNn from './data/nn.json';
import wordsNoUpper from './data/noUpperGrade.json';
import wordsNnUpper from './data/nnUpperGrade.json';
import wordsAudioNo from './data/noAudio';
import wordsAudioNn from './data/nnAudio';

import wordsAudioNoUpper from './data/noAudioUpper';
import wordsAudioNnUpper from './data/nnAudioUpper';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolumeHigh} from '@fortawesome/free-solid-svg-icons';

const wordsObj = {
	no: wordsNo,
	nn: wordsNn,
};
const wordsUpperGradeObj = {
	no: wordsNoUpper,
	nn: wordsNnUpper,
};

const audioObj = {
	no: wordsAudioNo,
	nn: wordsAudioNn,
};
const audioObjUpper = {
	no: wordsAudioNoUpper,
	nn: wordsAudioNnUpper,
};
let initialAudioIndex = 0;

class Test extends React.Component {
	constructor(props) {
		super(props);
		const selectedLanguage = strings.getLanguage() || 'no';
		this.words =
			this.props.candidateGrade < 5
				? wordsObj[selectedLanguage]
				: wordsUpperGradeObj[selectedLanguage];
		this.audio =
			this.props.candidateGrade < 5
				? audioObj[selectedLanguage]
				: audioObjUpper[selectedLanguage];
		const results = {};

		for (let question of this.words) {
			results[question.id] = '';
		}
		this.state = {
			results,
			timeRanOut: false,
			showWordAudio: false,
			currentClickedIndex: null,
			showChoiceAudio: false,
			currentChoiceIndex: null,
			showQuestionPlayButton: true,
			showChoicePlayButton: true,
		};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	render() {
		const {onNextStep, refs, isSendingTestResults} = this.props;

		const {timeRanOut, results} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues || timeRanOut;

		const minutes = 10;
		const minutesInHour = 60;

		return (
			<div className="lit-test lit-ordforstaelse">
				<div className="lit-infobar">
					<Countdown
						duration={minutes * minutesInHour}
						onComplete={() => {
							this.setState({
								timeRanOut: true,
							});
						}}
					/>
				</div>
				<p>{strings.chooseSynonym}</p>
				<div
					className={classNames({
						'lit-questions': true,
						'time-ran-out': timeRanOut,
					})}
				>
					{this.words.map((question, mainIndex) => {
						const {id, choices} = question;
						initialAudioIndex = initialAudioIndex + 1;
						const currentQuestionValue = results[id];
						const audioSource = this.audio[mainIndex].mainWord;

						return (
							<div className="lit-question" key={id}>
								<div className="question">
									<span className="lit-input__label lit-input__label--count">
										{id}
									</span>

									<span
										style={{
											display: 'flex',
										}}
									>
										<p className="question__text">
											{this.state.showWordAudio &&
												this.state
													.currentClickedIndex ===
													mainIndex && (
														<span style={{marginLeft:'-16px'}}>
													<AudioPlayer
														noBorder
														autoplay
														id={initialAudioIndex}
														refs={refs}
														src={audioSource}
														onErrorPlay={()=>{
															this.setState({
																showQuestionPlayButton: true,
																	currentChoiceIndex:
																		null,
																	showChoicePlayButton: true,
															});
														}}
														onComplete={() => {
															this.setState({
																showQuestionPlayButton: true,
																currentClickedIndex:
																	null,
																showChoicePlayButton: true,
															});
														}}
													/>
													</span>
												)}
											<span>
												{this.state
													.showQuestionPlayButton && (
													<FontAwesomeIcon
														size="lg"
														className="lit-play-button"
														icon={faVolumeHigh}
														onClick={() => {
															this.setState({
																showWordAudio: true,
																showQuestionPlayButton: false,
																currentClickedIndex:
																	mainIndex,
																showChoicePlayButton: false,
															});
														}}
													/>
												)}
												{!this.state
													.showQuestionPlayButton && 
													this.state
													.currentClickedIndex !==
													mainIndex && (
													<FontAwesomeIcon
														size="lg"
														className="lit-play-button-disabled"
														icon={faVolumeHigh}
														
													/>
												)}
											</span>
											<span>{question.word}</span>
										</p>
									</span>
								</div>
								<div className="choices">
									{choices.map((choice, choiceIndex) => {
										const choiceLetter = choice.letter;
										const idChoice = id + choiceLetter;

										initialAudioIndex =
											initialAudioIndex + 1;
										return (
											<span
												className="lit-choice"
												key={idChoice}
											>
												<input
													checked={
														currentQuestionValue ===
														choiceLetter
													}
													className="lit-choice__field"
													id={idChoice}
													onChange={() => {
														if (timeRanOut) {
															return;
														}
														this.setResults(
															id,
															choiceLetter
														);
													}}
													type="radio"
												/>

												<label
													className="lit-choice__label"
													htmlFor={idChoice}
												>
													<div className="checkbox" />
													<span className= {this.state.showChoiceAudio ?"lit-play-wrapper-wrapper":"lit-play-wrapper"}>
													{this.state
														.showChoiceAudio &&
														this.state
															.currentChoiceIndex ===
															`${mainIndex}${choiceIndex}` && (
																<span style={{marginLeft:'-16px'}}>
																<AudioPlayer
																	noBorder
																	autoplay
																	refs={refs}
																	onErrorPlay={()=>{
																		this.setState({
																			showQuestionPlayButton: true,
																				currentChoiceIndex:
																					null,
																				showChoicePlayButton: true,
																		});
																	}}
																	onComplete={() => {
																		this.setState(
																			{
																				showQuestionPlayButton: true,
																				currentChoiceIndex:
																					null,
																				showChoicePlayButton: true,
																			}
																		);
																	}}
																	src={
																		this
																			.audio[
																			mainIndex
																		][
																			'potentialSynonyms'
																		][
																			choiceIndex
																		]
																			.synonym
																	}
																/>
															</span>
														)}
														{''}
														</span>
														{this.state
															.showChoicePlayButton && (
															
																<FontAwesomeIcon
																size="lg"
																className="lit-play-button"
																icon={
																	faVolumeHigh
																}
																onClick={(
																	e
																) => {
																	e.preventDefault();
																	this.setState(
																		{
																			showChoiceAudio: true,
																			currentChoiceIndex: `${mainIndex}${choiceIndex}`,
																			showQuestionPlayButton: false,
																			showChoicePlayButton: false,
																		}
																	);
																}}
															/>

															
														)}
														{!this.state
															.showChoicePlayButton && this.state
															.currentChoiceIndex !==
															`${mainIndex}${choiceIndex}` && (
																<FontAwesomeIcon
																size="lg"
																className="lit-play-button-disabled"
																icon={
																	faVolumeHigh
																}
																
															/>

															
														)}
														{choice.choice}
													
												</label>
											</span>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>

				{isNextButtonVisible && (
					<button
						className="lit-btn bg-primary"
						onClick={() => onNextStep(results)}
						disabled={isSendingTestResults}
					>
						{strings.done}
					</button>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		testStepAnswers: {isSendingTestResults},
	} = state;

	return {
		isSendingTestResults,
	};
};
export default connect(mapStateToProps)(Test);
