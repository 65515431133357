import React from 'react';
import {connect} from 'react-redux';

import AudioPlayer from 'components/AudioPlayer';

import strings from 'utils/strings';

// import './styles.scss';

import audioNo from 'assets/sounds/no/6 1 Ordforstaelse Intro.mp3';
import audioNn from 'assets/sounds/nn/6 1 Ordforstaelse Intro.m4a';

import videoNn from './assets/nn/ordfortåelsepreview.mp4';
import videoNo from './assets/no/ordfortaelsepreviewno.mp4';
const audioObj = {
	no: audioNo,
	nn: audioNn,
};
const videoObj = {
	no: videoNo,
	nn: videoNn,
};

class Intro extends React.Component {
	constructor() {
		super();
		this.audio = audioObj[strings.getLanguage()];
		this.video = videoObj[strings.getLanguage()];
	}

	render() {
		const {onNextStep} = this.props;

		let videoRef;

		return (
			<div>
				<div className="text-left">
					<p>
						<AudioPlayer
							autoplay
							onErrorPlay={()=>{
								console.log('Something went wrong');
							}}
							inline
							onPause={() => {
								videoRef.play().catch((e)=>{
									console.log('can not play video', e);
								});
							}}
							src={this.audio}
						/>
						{strings.ordforstaelseIntro}
					</p>
					<p>{strings.ordforstaelseTimelimit}</p>
				</div>
				<div className="lit-preview">
					<p className="lit-preview__label">
						{strings.testLooksLikeThis}
					</p>
					<video
						className="lit-preview__video"
						loop
						ref={(ref) => {
							videoRef = ref;
						}}
						src={this.video}
					/>
				</div>
				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.start}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
