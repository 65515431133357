import React from 'react';
import {connect} from 'react-redux';

// import './styles.scss';
import tutorialVideo from './assets/4006868833093d06696ae39c73ba2ffb.mp4';

import strings from 'utils/strings';

import AudioPlayer from 'components/AudioPlayer';
import audioNo from 'assets/sounds/no/1 0 Orddiktat Intro.mp3';
import audioNn from 'assets/sounds/nn/1 0 Orddiktat Intro.mp3';

const audioObj = {
	no: audioNo,
	nn: audioNn,
};

class Intro extends React.Component {
	constructor() {
		super();
		this.audio = audioObj[strings.getLanguage()];
	}

	render() {
		const {onNextStep} = this.props;

		let videoRef;

		return (
			<div className="lit-intro">
				<p
					style={{
						marginBottom: 0,
					}}
				>
					<AudioPlayer
						autoplay
						onErrorPlay={()=>{
							console.log('Something went wrong');
						}}
						inline
						onPause={() => {
							videoRef.play();
						}}
						src={this.audio}
					/>
					{strings.orddiktatIntro}
				</p>
				<div className="text-center">
					<div className="lit-preview">
						<p className="lit-preview__label">
							{strings.testLooksLikeThis}
						</p>
						<video
							className="lit-preview__video"
							loop
							ref={ref => {
								videoRef = ref;
							}}
							src={tutorialVideo}
						/>
						
				</div>
					</div>
					
					<div>
					<p className="showme">{`"${strings.clickonplaybutton}"`}</p>
					</div>
				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.start}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
